const tColors = {
  't-dark': { base: '#353439' },
  't-grey': { base: '#6F7882' },
  't-back-light': { base: '#F7F9FC' },
  't-menu-bg': { base: '#E5EBF4' },
  't-additional': { base: '#7694B8' },
  't-positive': { base: '#00B040' },
  't-attention': { base: '#FF6B00' },
  't-disabled': { base: '#E1E3E6' },
  't-pink': { base: '#F7CCD1' },
};

export default {
  isOlveryPlatform: true,
  img: {
    publicBg: require('./assets/public.jpg'),
    authLogo: require('./assets/logo_vert.svg'),
    navLogo: require('./assets/logo_hor.svg'),
    accessPoster: require('./assets/access_poster.png'),
  },
  themes: {
    light: {
      primary: { base: '#025EA1' },
      secondary: { base: '#424242' },
      accent: { base: '#82B1FF' },
      error: { base: '#FF5252' },
      info: { base: '#2196F3' },
      success: { base: '#4CAF50' },
      warning: { base: '#FB8C00' },
      ...tColors,
    },
    dark: {
      primary: { base: '#025EA1' },
      secondary: { base: '#424242' },
      accent: { base: '#FF4081' },
      error: { base: '#FF5252' },
      info: { base: '#2196F3' },
      success: { base: '#4CAF50' },
      warning: { base: '#FB8C00' },
      ...tColors,
    },
  },
};
